import React from 'react'
import styled from 'styled-components'
import { Billboard, Button } from '@renderbus/common/components'
import { Media, pcSize, mbSize } from '@renderbus/common/theme'

import { useLocation } from '@reach/router'
import { useMedia } from 'use-media'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Title from '../../images/banner/year-end-recharge-2024-banner/year-end-recharge-title.png'
import TitleMb from '../../images/banner/year-end-recharge-2024-banner/year-end-recharge-title-mb.png'
import PageTitleMb from '../../images/banner/year-end-recharge-2024-banner/page-title-mb.png'
import AwardBg from '../../images/banner/year-end-recharge-2024-banner/award-bg.png'
import AwardBgMb from '../../images/banner/year-end-recharge-2024-banner/award-bg-mb.png'
import Award from '../../images/banner/year-end-recharge-2024-banner/award.png'
import PageBanner from '../../images/banner/year-end-recharge-2024-banner/page-banner-bg.png'
import PageBannerMb from '../../images/banner/year-end-recharge-2024-banner/page-banner-bg-mb.png'
import { baseLink } from '@renderbus/common/service'

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  .page-banner {
    position: absolute;
    background: #f2e0ce;
    width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    height: ${(p) => (p.isBanner ? '100vh' : '100%')};
    align-items: flex-start;
  }
`

const BannerContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 ${pcSize(160)} ${pcSize(187)};
  .right-bg {
    margin-left: -13px;
    width: ${pcSize(980)};
  }
  .page-award {
    z-index: 1;
  }
  ${Media.lessThan(Media.small)} {
    padding: ${(p) => (p.isBanner ? `${mbSize(149)} 0 0 0` : `${mbSize(169)} 0 ${mbSize(164)} 0`)};
    flex-direction: column;
    .right-bg {
      width: 100vw;
      margin-left: 0;
      margin-top: ${mbSize(-94)};
    }
    .page-award {
      margin-top: ${mbSize(-332)};
    }
  }
`
const BannerLeft = styled.div`
  z-index: 2;
  p {
    font-size: 18px;
    color: #ffffff;
    margin: ${pcSize(30)} 0 ${pcSize(33)} ${pcSize(50)};
  }
  img {
    width: ${pcSize(609)};
  }
  ${Media.lessThan(Media.small)} {
    img {
      width: 100vw;
    }
    p {
      text-align: center;
      font-size: ${mbSize(24)};
      margin: ${mbSize(30)} 0;
      line-height: ${mbSize(36)};
    }
    p.banner-text {
      margin: ${mbSize(-30)} 0 ${mbSize(30)};
    }
  }
`
const LearnButton = styled(Button)`
  width: 160px;
  height: 40px;
  margin-left: 50px;
  background: linear-gradient(90deg, #12cc75 0%, #1cc300 100%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #ffffff;
  :hover {
    box-shadow: 0px 10px 18px 2px rgba(153, 88, 23, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    margin: 0 auto;
    width: ${mbSize(240)};
    height: ${mbSize(60)};
    font-size: ${mbSize(24)};
    border-radius: ${mbSize(30)};
  }
`
const YearEndRecharge2023Banner = React.memo(function () {
  const location = useLocation()
  const isIndex = location.pathname === '/'
  const data = useStaticQuery(graphql`
    query {
      banner: file(
        relativePath: { eq: "banner/year-end-recharge-2024-banner/year-end-recharge-banner-bg.png" }
      ) {
        ...fluidImage
      }
      bannerMobile: file(
        relativePath: {
          eq: "banner/year-end-recharge-2024-banner/year-end-recharge-banner-bg-mobile.png"
        }
      ) {
        ...fluidImage
      }
    }
  `)
  const isMobile = useMedia({ maxWidth: 600 })

  return (
    <BannerContainer isBanner={isIndex}>
      <BannerContent isBanner={isIndex}>
        <BannerLeft>
          {isIndex ? (
            <img src={isMobile ? TitleMb : Title} alt='' />
          ) : (
            <img src={isMobile ? PageTitleMb : Title} alt='' />
          )}
          <p className={isIndex && 'banner-text'}>
            2024年11月8日10:00～12月27日12:00{isMobile ? <br /> : <span>,</span>}
            12月27日晚20:00统一直播抽奖
          </p>
          {isIndex ? (
            <LearnButton as={Link} to='/ysdhnzhd.html?utm_source=activity' id='ysdhnzhd-ljxq'>
              了解详情
            </LearnButton>
          ) : (
            <LearnButton as='a' href={`${baseLink(true)}/center/user/topUp`} id='ysdhnzhd-ljcz'>
              立即充值
            </LearnButton>
          )}
        </BannerLeft>
        {isIndex ? (
          <img src={isMobile ? AwardBgMb : AwardBg} alt='' className='right-bg' />
        ) : (
          <img src={isMobile ? Award : AwardBg} alt='' className='right-bg page-award' />
        )}
      </BannerContent>
      {isIndex ? (
        <Billboard
          fluid={[
            data.bannerMobile.childImageSharp.fluid,
            {
              ...data.banner.childImageSharp.fluid,
              media: `(min-width: 780px)`,
            },
          ]}
        />
      ) : (
        <img src={isMobile ? PageBannerMb : PageBanner} alt='' srcset='' className='page-banner' />
      )}
    </BannerContainer>
  )
})

export default YearEndRecharge2023Banner

import React from 'react'
import { SEO } from '@renderbus/common/components'
import { baseLink, isTest } from '@renderbus/common/service'
import {
  ContentContainer,
  TitleContent,
  PrizeList,
  PrizeItem,
  PrizeImgWrap,
  RulesList,
  DescriptionList,
  StepImgWrap,
  LotteryContent,
  LotterySubTitle,
  JoinActivityButton,
  YearEndRechargeBg,
  YearEndWrapper,
  PrizeImg,
  PrizeContent,
  PrizeMbContent,
  HighltText,
  LotteryContentContainer,
  JoinNowButton,
} from './ysdhnzhd.atom'
import { YearEndRecharge2023Banner } from '../molecules/banner'
import { API, Axios } from '@renderbus/common/utils'

import PrizeImage1 from '../images/year-end-recharge/2024/prize/prize-1.png'
import PrizeImage2 from '../images/year-end-recharge/2024/prize/prize-2.png'
import PrizeImage3 from '../images/year-end-recharge/2024/prize/prize-3.png'
import PrizeImage4 from '../images/year-end-recharge/2024/prize/prize-4.png'
import PrizeImage5 from '../images/year-end-recharge/2024/prize/prize-5.png'
import PrizeImage6 from '../images/year-end-recharge/2024/prize/prize-6.png'
import PrizeImage7 from '../images/year-end-recharge/2024/prize/prize-7.png'
import PrizeImage8 from '../images/year-end-recharge/2024/prize/prize-8.png'
import CashPrize from '../images/year-end-recharge/2024/cash-prize.png'
import MorePrize from '../images/year-end-recharge/2024/more-prize.png'
import MyPrizeCode from '../images/year-end-recharge/2024/my-prize-code.png'
import CashPrizeHover from '../images/year-end-recharge/2024/cash-prize-hover.png'
import MorePrizeHover from '../images/year-end-recharge/2024/more-prize-hover.png'
import MyPrizeCodeHover from '../images/year-end-recharge/2024/my-prize-code-hover.png'

import stepPC from '../images/year-end-recharge/2024/activity-rule-step.png'
import stepMobile from '../images/year-end-recharge/2024/activity-rule-step-mb.png'
import Layout from '../molecules/layout'
import PointerIcon from '../images/year-end-recharge/2024/pointer.png'

import Title01 from '../images/year-end-recharge/2024/title-1.png'
import Title02 from '../images/year-end-recharge/2024/title-2.png'
import Title03 from '../images/year-end-recharge/2024/title-3.png'
import Title04 from '../images/year-end-recharge/2024/title-4.png'
import RechargeGift from '../images/year-end-recharge/2024/recharge-gift.png'
import Star from '../images/year-end-recharge/2024/star.png'
import GiftBox from '../images/year-end-recharge/2024/gift-box.png'

const prizeImgList = [
  {
    name: '英伟达 RTX A4000显卡 ×1',
    subname: '',
    nameMb: `英伟达 RTX A4000显卡 ×1`,
    src: PrizeImage1,
  },
  {
    name: 'iPhone 16（128GB）颜色随机 ×2   ',
    subname: '',
    nameMb: 'iPhone 16（128GB）',
    secondNameMb: '颜色随机×2',
    src: PrizeImage2,
  },
  {
    name: '极米Z7X 高亮版投影仪 畅玩黑神话',
    subname: '（880CVIA  哈曼原装音响 ）×3',
    nameMb: '极米Z7X 高亮版投影仪',
    secondNameMb: '畅玩黑神话（880CVIA',
    thirdNameMb: '哈曼原装音响 ）×3',
    src: PrizeImage3,
  },
  {
    name: 'GoPro HERO10 Black 运动相机',
    subname: '官方标配标准版 ×4',
    nameMb: 'GoPro HERO10 Black',
    secondNameMb: '运动相机 官方标配',
    thirdNameMb: '标准版 ×4',
    src: PrizeImage4,
  },
  {
    name: '雷蛇Razer 旋风黑鲨V2 有线头戴式',
    subname: '电竞游戏耳机（3.5mm+USB声卡）×5',
    nameMb: '雷蛇Razer 旋风黑鲨V2',
    secondNameMb: '有线头戴式电竞游戏耳机',
    thirdNameMb: '（3.5mm+USB声卡）×5',
    src: PrizeImage5,
  },
  {
    name: '小米智能手环9 NFC版 黑色 ×20',
    subname: '',
    nameMb: '小米 智能手环9',
    secondNameMb: 'NFC版 黑色 ×20',
    src: PrizeImage6,
  },
  {
    name: 'Usmile笑容加 电动牙刷P10',
    subname: '昆仑白 ×30',
    nameMb: 'Usmile笑容加 电动牙刷',
    secondNameMb: 'P10昆仑白 ×30',
    src: PrizeImage7,
  },
  {
    name: 'Renderbus 定制U型枕/午睡毯',
    subname: '（参与可得，随机发货）',
    nameMb: 'Renderbus 定制',
    secondNameMb: 'U型枕/午睡毯',
    thirdNameMb: '（参与可得，随机发货）',
    src: PrizeImage8,
  },
]

const MidYearRecharge2023 = React.memo(function (props) {
  const {
    location,
    pageContext: { topThreeShare },
  } = props

  const handleRecharge = (url) => {
    window.location.href = url
  }

  const getMorePrizeCode = async () => {
    const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)
    const hasActivityQualification = await instance.post(API.event.getSupportActivity)
    if (hasActivityQualification && [602, 603].includes(hasActivityQualification.code)) {
      handleRecharge(`${baseLink(true)}/center/user/topUp`)
      return
    }
    if (!(hasActivityQualification.data && hasActivityQualification.data.length)) return
    const { code, data } = await instance.post(API.event.getUserPrizeCode, {
      activityId: hasActivityQualification.data[0].id,
    })

    if (data && code === 200) {
      if (!data.length) handleRecharge(`${baseLink(true)}/center/user/topUp`)
      if (data && data.filter((item) => item.payRecordId).length) {
        handleRecharge(
          `${
            isTest() ? 'http://pre.renderbus.com:8081' : 'https://www.renderbus.com'
          }/nvidia-form.html?activityId=${hasActivityQualification.data[0].id}`,
        )
      }
    }
  }

  return (
    <Layout location={location} topThreeShare={topThreeShare}>
      <SEO
        title='2024瑞云影视动画年末狂欢，充值即享豪礼活动-瑞云渲染'
        keywords='2024瑞云动画年末充值活动,瑞云影视动画充值送礼,瑞云渲染动画年终大促'
        description='瑞云渲染2024年终大促来袭！影视动画云渲染用户专享，充值即有机会抽取英伟达RTX A4000显卡、iPhone16等大奖。年末超值优惠，提升制作效率，丰厚回报等你来拿。立即参与，赢取豪礼！'
        sharePostSlug='ysdhnzhd.html'
      />
      <YearEndRecharge2023Banner />
      <YearEndRechargeBg>
        <YearEndWrapper>
          <TitleContent mT='0' mdPaddingTop='10px'>
            <img src={Title01} alt='' />
          </TitleContent>
          <ContentContainer>
            <PrizeList>
              {prizeImgList.map((item, index) => {
                return (
                  <PrizeItem key={index}>
                    <PrizeImgWrap>
                      <PrizeImg src={item.src} alt={item.name} />
                    </PrizeImgWrap>
                    <PrizeContent>
                      {item.name}
                      <br />
                      {item.subname}
                    </PrizeContent>
                    <PrizeMbContent>
                      {item.nameMb}
                      <br />
                      {item.secondNameMb}
                      <br />
                      {item.thirdNameMb}
                    </PrizeMbContent>
                    {/* <PrizeMbContent>
                      {item.name}
                      <br />
                      {item.subname}
                      <br />
                      {item.subname1}
                    </PrizeMbContent> */}
                  </PrizeItem>
                )
              })}
            </PrizeList>
            <JoinActivityButton
              as='a'
              id='ysdhnzhd-ljcz'
              onClick={() => handleRecharge(`${baseLink(true)}/center/user/topUp`)}
            >
              立即参与
            </JoinActivityButton>
            <img src={GiftBox} alt='' className='gift-box-img' />
          </ContentContainer>
          <TitleContent>
            <img src={Title02} alt='' />
          </TitleContent>
          <ContentContainer>
            <StepImgWrap>
              <img src={stepPC} alt='活动规则' />
              <img src={stepMobile} alt='活动规则' />
              <RulesList>
                <p>【 参与方式 】</p>
                <div>
                  活动期间，影视动画用户单笔充值
                  <HighltText>每满5000元</HighltText>
                  ，即可获得1张抽奖券，获得奖券后再
                  <HighltText>填写问卷</HighltText>
                  即可额外多获得1张抽奖券，每个用户通过充值最多可获得
                  <HighltText>30张</HighltText>抽奖券
                </div>
              </RulesList>
              <RulesList>
                <p>【 活动时间 】</p>
                <span>2024年11月8日10:00～2024年12月27日12:00，12月27日晚20:00统一直播抽奖</span>
              </RulesList>
              <RulesList>
                <p>【 活动范围 】</p>
                <span> Renderbus影视动画用户，青云平台用户不参与本次活动</span>
              </RulesList>
              <RulesList>
                <p>【 开奖时间 】</p>
                <span>2024年12月27日晚20:00，B站直播间（@Renderbus瑞云渲染 ）统一直播开奖</span>
              </RulesList>
              <RulesList>
                <p>【 兑奖方式 】</p>
                <span>
                  用户中奖后，请在瑞云渲染官网活动页面-兑奖专区-立即兑奖，或者点击官网充值页面导航栏
                  <img src={RechargeGift} alt='' className='recharge-gift-img' />
                  图标处，查看奖券并填写相关兑奖信息进行兑奖
                </span>
              </RulesList>
              <RulesList>
                <p>【 兑奖时间 】</p>
                <span>2024年12月28日10点开放兑奖链接</span>
              </RulesList>
              <RulesList>
                <p>【 兑奖截止时间 】</p>
                <span>2025年1月15日18:00</span>
              </RulesList>
            </StepImgWrap>
          </ContentContainer>
          <TitleContent>
            <img src={Title03} alt='' />
          </TitleContent>
          <ContentContainer>
            <DescriptionList>
              <p>
                <span>1. </span>
                如对本活动有任何问题，可以微信添加瑞云小助手（微信号：rayvision1）进行咨询
              </p>
              <p>
                <span>2. </span>
                青云平台用户不参与本次活动，若动画用户企业账号参与活动，则以主账号为准
              </p>
              <p>
                <span>3.</span>
                每个用户通过充值最多可获得30张抽奖券；没有中奖的客户可凭一张券兑换一套周边，多券多换
              </p>
              <p>
                <span>4.</span>
                已中奖的用户不可再兑参与奖，但如果用户超过15张抽奖券且只获六等奖的情况下，可单独找业务经理申请兑换剩余奖券的参与奖（封顶15套），用户超过10张抽
                奖券且只获七等奖的情况下，可单独找业务经理申请兑换剩余奖券的参与奖（封顶20套）
              </p>
              <p>
                <span>5.</span>
                <span>
                  已充值5000元以上的用户，登录后可点击导航栏
                  <img src={RechargeGift} alt='' />
                  图标，查看已获得的抽奖券和问卷，开奖后也可以到该页面填写兑奖信息
                </span>
              </p>
              <p>
                <span>6. </span>只有已经获得抽奖券的用户才能填写问卷，获得更多奖券码
              </p>
              <p>
                <span>7.</span>
                抽奖奖品一律不支持折换现金，未在截止日期前兑换奖品的用户视为放弃奖品
              </p>
              <p>
                <span>8.</span>
                本次活动最终解释权归深圳市瑞云科技股份有限公司所有，瑞云有权对恶意刷抢活动资源、利用活动资源从事违法违规行为的用户收回活动参与资格，并对涉事账号进行封禁处理！
              </p>
            </DescriptionList>
          </ContentContainer>
          <TitleContent iconSpacing={'37%'} iconMbSpacing={'24%'}>
            <img src={Title04} alt='' />
          </TitleContent>
          <LotteryContentContainer>
            <img src={Star} alt='' className='star-img' />
            <LotteryContent
              as='a'
              onClick={() => getMorePrizeCode()}
              target='_blank'
              id='ysdhnzhd-txwj'
              hoverUrlSrc={MorePrizeHover}
              urlSrc={MorePrize}
            >
              <div class='lottery-img' />
              <div class='lottery-title'>获取更多奖券</div>
              <LotterySubTitle>填写调查问卷</LotterySubTitle>
              <LotterySubTitle>可额外获一张抽奖券</LotterySubTitle>
            </LotteryContent>
            <LotteryContent
              as='a'
              onClick={() =>
                handleRecharge(`${baseLink(true)}/center/user/topUp?openCashPrize=true`)
              }
              target='_blank'
              id='ysdhnzhd-djck'
              urlSrc={MyPrizeCode}
              hoverUrlSrc={MyPrizeCodeHover}
            >
              <div class='lottery-img' />
              <div class='lottery-title'>我的奖券码</div>
              <LotterySubTitle>点击查看</LotterySubTitle>
              <LotterySubTitle>已获得奖券码及中奖情况</LotterySubTitle>
            </LotteryContent>
            <LotteryContent
              as='a'
              onClick={() =>
                handleRecharge(`${baseLink(true)}/center/user/topUp?openCashPrize=true`)
              }
              target='_blank'
              id='ysdhnzhd-djdhjp'
              urlSrc={CashPrize}
              hoverUrlSrc={CashPrizeHover}
            >
              <div class='lottery-img' />
              <div class='lottery-title'>兑奖专区</div>
              <LotterySubTitle>点击兑换奖品</LotterySubTitle>
            </LotteryContent>
          </LotteryContentContainer>
          <JoinNowButton
            as='a'
            onClick={() => handleRecharge(`${baseLink(true)}/center/user/topUp`)}
            target='_blank'
            id='ysdhnzhd-ljcyhd'
          >
            立即参与活动，抽取壕华礼品！
            <img src={PointerIcon} alt='pointer' />
          </JoinNowButton>
        </YearEndWrapper>
      </YearEndRechargeBg>
    </Layout>
  )
})

export default MidYearRecharge2023

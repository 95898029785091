import React from 'react'
import { Button, Content, Title, Wrapper } from './atoms'
import { graphql, useStaticQuery } from 'gatsby'
import { Billboard } from '@renderbus/common/components'
import title from '../../../images/rc2025/title.webp'

const query = graphql`
  query {
    banner: file(relativePath: { regex: "/banner/rc2025/banner.webp/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/banner/rc2025/banner-mb.webp/" }) {
      ...fluidImage
    }
  }
`

export const RC2025Banner = () => {
  const { banner, bannerMb } = useStaticQuery(query)

  return (
    <Wrapper>
      <Content>
        <Title src={title} alt='新途' />
        <Button href='/rc2025.html' target='__blank'>
          <span>查看详情</span>
        </Button>
      </Content>
      <Billboard
        fluid={[
          banner.childImageSharp.fluid,
          { ...bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </Wrapper>
  )
}

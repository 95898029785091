import React from 'react'
import styled from 'styled-components'
import { Billboard, Image } from '@renderbus/common/components'
import { Media, mbSize, bannerSize, pcSize } from '@renderbus/common/theme'
import { graphql, Link, useStaticQuery } from 'gatsby'

export const TitleWrapper = styled.div`
  width: ${bannerSize(528)};
  height: ${bannerSize(73)};
  position: relative;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(414)};
    height: ${mbSize(50)};
  }
`

export const TextWrapper = styled.div`
  position: relative;
  width: ${bannerSize(699)};
  height: ${bannerSize(179)};
  margin-top: ${bannerSize(48)};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(590)};
    height: ${mbSize(152)};
    margin-top: ${mbSize(27)};
  }
`

export const LabelImage = styled(Image)`
  top: ${bannerSize(168)};
  left: ${bannerSize(361)};
  width: ${bannerSize(484)};
  height: ${bannerSize(174)};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(346)};
    height: ${mbSize(186)};
    top: ${mbSize(142)};
    left: ${mbSize(321)};
  }
`

export const QRCodeWrapper = styled.div`
  position: relative;
  width: ${bannerSize(489)};
  height: ${bannerSize(117)};
  margin-top: ${bannerSize(16)};
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(412)};
    height: ${mbSize(99)};
    margin-top: ${mbSize(56)};
  }
`

export const DoubleElevenContainer = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
  color: white;
  ${Media.lessThan(Media.small)} {
    align-items: flex-start;
    text-align: center;
  }
`

export const DoubleElevenContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${bannerSize(1400)};
  padding-top: ${bannerSize(237)};
  height: 100%;
  ${Media.lessThan(Media.small)} {
    padding-top: ${mbSize(128)};
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: ${bannerSize(282)};
  height: ${bannerSize(100)};
  margin-top: ${bannerSize(35)};
  .hover {
    visibility: hidden;
    pointer-events: none;
  }
  &:hover {
    .hover {
      visibility: visible;
      pointer-events: unset;
    }
    .normal {
      visibility: hidden;
      pointer-events: none;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(282)};
    height: ${mbSize(100)};
    margin-top: ${mbSize(53)};
  }
`

export default React.memo(function DoubleElevenBanner() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { regex: "/banner/double-eleven/banner-bg.webp/" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { regex: "/banner/double-eleven/banner-bg-mb.webp/" }) {
        ...fluidImage
      }
      titleImg: file(relativePath: { regex: "/banner/double-eleven/title.webp/" }) {
        ...fluidImage
      }
      labelImg: file(relativePath: { regex: "/banner/double-eleven/label.webp/" }) {
        ...fluidImage
      }
      labelMbImg: file(relativePath: { regex: "/banner/double-eleven/label-mb.webp/" }) {
        ...fluidImage
      }
      textImg: file(relativePath: { regex: "/banner/double-eleven/text.webp/" }) {
        ...fluidImage
      }
      qrCodeImg: file(relativePath: { regex: "/banner/double-eleven/qr-code.webp/" }) {
        ...fluidImage
      }
      btnNormal: file(relativePath: { regex: "/banner/double-eleven/btn-normal.webp/" }) {
        ...fluidImage
      }
      btnHover: file(relativePath: { regex: "/banner/double-eleven/btn-hover.webp/" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <DoubleElevenContainer to='/architectural-rendering-monthly-promotion.html?utm_source=index-banner'>
      <DoubleElevenContent>
        <TitleWrapper>
          <Image
            fluid={[
              data.titleImg.childImageSharp.fluid,
              { ...data.titleImg.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
        </TitleWrapper>
        <TextWrapper>
          <Image
            fluid={[
              data.textImg.childImageSharp.fluid,
              { ...data.textImg.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
          <LabelImage
            fluid={[
              data.labelImg.childImageSharp.fluid,
              { ...data.labelMbImg.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
        </TextWrapper>
        <QRCodeWrapper>
          <Image
            fluid={[
              data.qrCodeImg.childImageSharp.fluid,
              { ...data.qrCodeImg.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
        </QRCodeWrapper>
        <ButtonWrapper>
          <Image
            className='normal'
            fluid={[
              data.btnNormal.childImageSharp.fluid,
              { ...data.btnNormal.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
          <Image
            className='hover'
            fluid={[
              data.btnHover.childImageSharp.fluid,
              { ...data.btnHover.childImageSharp.fluid, media: `(max-width: 780px)` },
            ]}
          />
        </ButtonWrapper>
      </DoubleElevenContent>
      <Billboard
        fluid={[
          data.banner.childImageSharp.fluid,
          { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
        ]}
      />
    </DoubleElevenContainer>
  )
})

import styled from 'styled-components'
import buttonHover from '../../../images/banner/rc2025/button-hover.webp'
import button from '../../../images/banner/rc2025/button.webp'
import YouSheBiaoTiHei from '../../../font/YouSheBiaoTiHei.ttf'
import { mbSize, Media } from '@renderbus/common/theme'

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 38%;
  left: 62%;
  transform: translate(-50%, -50%);
  ${Media.lessThan(Media.small)} {
    top: unset;
    left: unset;
    transform: unset;
    margin-top: ${mbSize(430)};
  }
`

export const Title = styled.img`
  width: 694px;
  height: 310px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(686)};
    height: ${mbSize(336)};
  }
`

export const Button = styled.a`
  @font-face {
    font-family: 'YouSheBiaoTiHei';
    src: url(${YouSheBiaoTiHei});
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 227px;
  height: 60px;
  background: url(${button});
  transition: all 0.3s ease-in-out;
  background-size: 100%;
  font-family: YouSheBiaoTiHei;
  font-size: 24px;
  margin-top: 20px;
  cursor: pointer;
  span {
    background: linear-gradient(274deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 104%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &:hover {
    background: url(${buttonHover});
    background-size: 100%;
    span {
      background: linear-gradient(90deg, #f0feea -1%, #febd2f 24%, #26fdf8 71%, #ffffff 104%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(372)};
    height: ${mbSize(98)};
    font-size: ${mbSize(40)};
  }
`
